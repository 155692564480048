.container {
  min-width: 100vw;
  min-height: 100vh;
  /* background-color: rgb(50, 252, 97); */
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
  padding-left: 2rem;
  padding-right: 2rem;
  background: linear-gradient(135deg, #ff6b6b, #3a1c71);
  filter : hue-rotate(90deg);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  overflow: hidden;
  }
  animate {
    animation: gradient 20s ease infinite;
}
@keyframes gradient {
    0% {
        filter: hue-rotate(0deg);
    }
    50% {
        filter: hue-rotate(360deg);
    }
    100% {
        filter: hue-rotate(0deg);
    }
}
  
  .loginForm {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    width: 300px;
    text-align: center;
    font-family: Arial, sans-serif;
    margin: 20px;
  }
  
  h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    margin-top: 5px;
  }
  
  input {
    width: 100%;
    padding: 8px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  button {
    width: 100%;
    background-color: #515151;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .text {
    font-size: 14px;
    margin-top: 10px;
  }
  
  .text a {
    text-decoration: none;
    color: #007bff;
  }
  .bg_bubbles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  .bg_bubbles li {
    position: absolute;
    list-style: none;
    display: block;
    width: 40px;
    height: 40px;
    background-color: rgba(255, 255, 255, 0.15);
    bottom: -160px;
    -webkit-animation: square 20s infinite;
    animation: square 10s infinite;
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
  }
  .bg_bubbles li:nth-child(1) {
    left: 10%;
  }
  .bg_bubbles li:nth-child(2) {
    left: 20%;
    width: 80px;
    height: 80px;
    -webkit-animation-delay: 2s;
            animation-delay: 2s;
    -webkit-animation-duration: 17s;
            animation-duration: 17s;
  }
  .bg_bubbles li:nth-child(3) {
    left: 25%;
    -webkit-animation-delay: 4s;
            animation-delay: 4s;
  }
  .bg_bubbles li:nth-child(4) {
    left: 40%;
    width: 60px;
    height: 60px;
    -webkit-animation-duration: 22s;
            animation-duration: 22s;
    background-color: rgba(255, 255, 255, 0.25);
  }
  .bg_bubbles li:nth-child(5) {
    left: 70%;
  }
  .bg_bubbles li:nth-child(6) {
    left: 80%;
    width: 120px;
    height: 120px;
    -webkit-animation-delay: 3s;
            animation-delay: 3s;
    background-color: rgba(255, 255, 255, 0.2);
  }
  .bg_bubbles li:nth-child(7) {
    left: 32%;
    width: 160px;
    height: 160px;
    -webkit-animation-delay: 7s;
            animation-delay: 7s;
  }
  .bg_bubbles li:nth-child(8) {
    left: 55%;
    width: 20px;
    height: 20px;
    -webkit-animation-delay: 15s;
            animation-delay: 15s;
    -webkit-animation-duration: 40s;
            animation-duration: 40s;
  }
  .bg_bubbles li:nth-child(9) {
    left: 25%;
    width: 10px;
    height: 10px;
    -webkit-animation-delay: 2s;
            animation-delay: 2s;
    -webkit-animation-duration: 40s;
            animation-duration: 40s;
    background-color: rgba(255, 255, 255, 0.3);
  }
  .bg_bubbles li:nth-child(10) {
    left: 90%;
    width: 160px;
    height: 160px;
    -webkit-animation-delay: 11s;
            animation-delay: 11s;
  }
  @-webkit-keyframes square {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(-700px) rotate(600deg);
              transform: translateY(-700px) rotate(600deg);
    }
  }
  @keyframes square {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(-700px) rotate(600deg);
              transform: translateY(-700px) rotate(600deg);
    }
  }
  /* Login.module.css */
.loginForm {
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  border-radius: 8px;
  padding: 10px;
  margin: 10px 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s;
  color: white;
  font-size: 16px;
  width:30vw;
}
@media (max-width: 600px){
  .loginForm{
    width: 80vw;
  }
}
.loginForm input {
  background: none;
  border: none;
  outline: none;
  width: 100%;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.inputs::placeholder {
  color: white;
}
