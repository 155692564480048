/* Footer.module.css */
.footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 120px;
  box-sizing: border-box;
  background-color: #212529;
  color: #fff;
  text-align: center;
  padding: 4px;
  position: sticky; /* Change to fixed position */
  bottom: 0;
  width: 100%;
  z-index: 1000; /* Set a high z-index to ensure the footer appears above other content */
}
@media (max-width: 768px) {
  .footer {
    justify-content: center; /* Center content in mobile view */
    min-height: auto; /* Remove minimum height in mobile view */
  }
}