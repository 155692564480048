.choicesScreenContainer {
  padding: 2rem;
}

.categoryContainer {
  margin-bottom: 1.5rem;
}

.categoryTitle {
  font-size: 1.2rem;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.selectedPlayer {
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
}
.playerImage {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.selectedPlayer .playerImage {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}
