/* Live.module.css */
.container{
  margin-top: 5rem;
}

.header {
  padding: 16px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  font-weight: 900;
}
@media (max-width: 600px){
  
}
.headerContent {
  display: flex;
  align-items: center;
}

.teamImage {
  width: 40px;
  height: 40px;
  margin-right: 16px;
}

.vs {
  font-size: 24px;
  margin: 0 16px;
}

.currentRuns {
  font-size: 20px;
  margin-left: 37vw;
}
@media (max-width: 600px){
  .currentRuns{
    margin-left: 19vw;
  }
}
/* Add this to your CSS file */
.centered-section {
  text-align: center;
  padding: 10px;
  background-color: #f5f5f5; /* Add your desired background color */
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 20px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}


.tossStadiumInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  background-color: #f0f0f0;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.toss {
  font-size: 18px;
  margin: 0;
}

.stadium {
  font-size: 16px;
  margin: 0;
}

.statsTable {
  margin: 16px 0;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.statsTable thead {
  background-color: #333;
  color: #fff;
}

.statsTable th,
.statsTable td {
  padding: 8px;
  text-align: center;
}

.statsTable tr:hover {
  background-color: #f5f5f5;
}

.statsTable tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.currentPlayers{
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #fff;
  text-transform: capitalize;
}
.card{
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  padding: 16px;
}
.matchStatsHeading{
  font-size: 25px;
  margin: 0;
  font-weight: bold;
  margin-bottom: 20px;

}

.extrasAndWickets{
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-around;
  padding: 16px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;

}

.fallOfWicketsTable{
  margin:2rem;
}

.extrasTable{
  margin:2rem;
}
.result{
  margin: auto;
}

